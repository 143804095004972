import { Avatar, Box, Button, Container, Grid, IconButton, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import React, { useRef } from 'react'
import videoHeader_PC from '../assets/video/PC 01_4.mp4'
import videoHeader_MB from '../assets/video/header_video_mobile.gif'
import banner_01 from '../assets/harper-sunday-ZY5zht6lT6I-unsplash.png'
import bag_01 from '../assets/pngwing.com_(4).png'
import bag_02 from '../assets/pngwing.com_(5).png'
import bag_01_mobile from '../assets/pngwing.com_mobile2.png'
import bag_02_mobile from '../assets/pngwing.com_mobile.png'
import product_01 from '../assets/product-01.png'
import product_02 from '../assets/product-02.png'
import product_03 from '../assets/product-03.png'
import product_04 from '../assets/product-04.png'
import product_05 from '../assets/product-05.png'
import product_06 from '../assets/product-06.png'
import product_07 from '../assets/product-07.png'
import product_08 from '../assets/product-08.png'
import product_09 from '../assets/product-09.png'
import product_10 from '../assets/product-10.png'
import product_11 from '../assets/product-11.png'
import product_12 from '../assets/product-12.png'
import product_13 from '../assets/product-13.png'
import product_14 from '../assets/product-14.png'
import product_15 from '../assets/product-15.png'
import product_16 from '../assets/product-16.png'
import product_17 from '../assets/product-17.png'
import product_18 from '../assets/product-18.png'
import product_19 from '../assets/product-19.png'
import product_20 from '../assets/product-20.png'
import product_21 from '../assets/product-21.png'
import product_22 from '../assets/product-22.png'
import product_23 from '../assets/product-23.png'
import product_24 from '../assets/product-24.png'
import product_25 from '../assets/product-25.png'
import product_26 from '../assets/product-26.png'
import product_27 from '../assets/product-27.png'
import product_28 from '../assets/product-28.png'
import product_29 from '../assets/product-29.png'
import product_30 from '../assets/product-30.png'
import product_31 from '../assets/product-31.png'
import product_32 from '../assets/product-32.png'
import product_33 from '../assets/product-33.png'
import arrow from '../assets/icon/arrow-down-sign-to-navigate.png'

import icon_ig from '../assets/icon/IG.png'
import icon_line from '../assets/icon/Line.png'
import icon_mail from '../assets/icon/mail.png'
import icon_phone from '../assets/icon/phone.png'
import icon_ring from '../assets/icon/ring.png'
import icon_shield from '../assets/icon/shield.png'
import icon_s from '../assets/icon/S.png'
import image_1 from '../assets/bags-01.png'
import image_1_mobile from '../assets/bags-01mobile.png'
import { styled } from '@mui/material/styles';

function HomeTH() {
    const about_us_pc = useRef(null);
    const trending_pc = useRef(null);
    const accessories_pc = useRef(null);
    const bags_pc = useRef(null);
    const cloths_pc = useRef(null);
    const footwears_pc = useRef(null);
    const sell_with_us_pc = useRef(null);
    const customer_pc = useRef(null);
    const about_us = useRef(null);
    const trending = useRef(null);
    const accessories = useRef(null);
    const bags = useRef(null);
    const cloths = useRef(null);
    const footwears = useRef(null);
    const sell_with_us = useRef(null);
    const customer = useRef(null);

    return (
        <>
            <Box sx={{ display: { lg: 'block', md: 'block', xs: 'none' } }}>
                <div style={{ position: 'fixed' }}>
                    <video autoPlay={true} muted loop height={window.screen.height} width='auto' >
                        <source src={videoHeader_PC || ''} type='video/mp4' />
                    </video>
                </div>
                <div className='first' style={{
                    position: 'fixed',
                    height: 'calc(100vh + 10vh)',
                    width: '100%'
                }}>
                    <Container maxWidth='lg'>
                        <Stack spacing={10} height='100vh' justifyContent='center' alignItems='center'>
                            <Typography align='center' color='white' fontSize={60} fontFamily={'Playfair Display'} fontWeight={700}>LUXURYSIAM</Typography>
                            <Stack direction='row' spacing={2} justifyContent='center'>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => about_us_pc.current?.scrollIntoView({ behavior: 'smooth' })}>About us</Button>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => trending_pc.current?.scrollIntoView({ behavior: 'smooth' })}>Trending</Button>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => accessories_pc.current?.scrollIntoView({ behavior: 'smooth' })}>Accessories</Button>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => bags_pc.current?.scrollIntoView({ behavior: 'smooth' })}>Bags</Button>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => cloths_pc.current?.scrollIntoView({ behavior: 'smooth' })}>Clothes</Button>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => footwears_pc.current?.scrollIntoView({ behavior: 'smooth' })}>Footwears</Button>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => sell_with_us_pc.current?.scrollIntoView({ behavior: 'smooth' })}>Sell with us</Button>
                                <Button sx={{ p: 0, color: '#fff' }} onClick={() => customer_pc.current?.scrollIntoView({ behavior: 'smooth' })}>Customer service</Button>
                            </Stack>
                            <Stack>
                                <Stack direction='row' spacing={3} alignItems='center' justifyContent='center'>
                                    <Typography color='white' align='center' fontWeight={700} fontSize={45}>"</Typography>
                                    <Stack>
                                        <Typography color='white' align='center' fontWeight={700} fontSize={45}>Trends come and go,</Typography>
                                        <Typography color='white' align='center' fontWeight={700} fontSize={45}>but classics are forever.</Typography>
                                    </Stack>
                                    <Typography color='white' align='center' fontWeight={700} fontSize={45}>"</Typography>
                                </Stack>
                                <Grid container justifyContent='center'>
                                    <Grid item xs={8}>
                                        <Typography color='white' align='justify' fontWeight={600}>With the Anatomy of an Investment Piece, we highlight the timeless styles you need in your wardrobe and break down what exactly makes them worth buying. Whether it’s a forever bag, wear-everywhere coat, or footwear staple, these fashion pieces are icons in the making.</Typography>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                        <Stack alignItems='center'>
                            <IconButton onClick={() => about_us_pc.current?.scrollIntoView({ behavior: 'smooth' })}>
                                <Avatar src={arrow} sx={{ width: 15, height: 15 }} />
                            </IconButton>
                        </Stack>
                    </Container>
                </div>

                <div style={{ position: 'relative', top: 'calc(100vh + 5vh)', backgroundColor: '#fff' }}>
                    <Container maxWidth='lg'>
                        <Grid container spacing={5} justifyContent='center' alignItems='center' pt={5}>
                            <Grid item xs={12} >
                                <Typography ref={about_us_pc} align='center' fontSize={36} fontFamily={'Playfair Display'} fontWeight={700}>Luxurysiam's</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography textAlign='justify'>
                                    <b>story begins with a profound passion for luxury brands</b> and an appreciation for their rich histories. The timeless elegance of designer apparel, shoes, bags, and accessories not only enhances your personal style but also reflects sophistication and credibility. Luxury brands epitomize global fashion, remaining perpetually in vogue and becoming cherished heirlooms passed down through generations.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    backgroundImage: `url(${banner_01})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    height: '350px'
                                }} />
                            </Grid>
                            <Grid item xs={3.25}>
                                <Stack alignItems='flex-start'>
                                    <img src={bag_02} width='auto' height='300px' />
                                </Stack>
                            </Grid>
                            <Grid item xs={5.5}>
                                <Stack alignItems='center'>
                                    <Typography align='center' fontSize={36} fontFamily={'Playfair Display'} fontWeight={700}>Established in 2012,</Typography>
                                    <Typography align='justify'>our journey started with personal collections that sparked the idea of offering pristine pre-owned items. Our selection boasts items in impeccable condition, ranging from 95-99%, complete with original accessories, and offered at a more attractive price than new. We also curate a collection of brand-new items for discerning customers.</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.25}>
                                <Stack alignItems='flex-end'>
                                    <img src={bag_01} width='auto' height='300px' />
                                </Stack>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography textAlign='justify' mt={3} mb={10}>
                                    Additionally, <b>we specialize in sourcing rare and coveted pieces tailored to your desires,</b> whether they hail from England, France, or Italy, thanks to our frequent international travels. <b>Luxurysiam Co., Ltd</b> was founded to facilitate the acquisition and sale of authentic luxury items. Our headquarters, legally registered and based in Bangkok, ensure trust and credibility.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={5} justifyContent='center' >
                            <Grid item xs={11}>
                                <Typography ref={trending_pc} fontSize={36} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600}>Trending</Typography>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_01} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>27,600 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={18} align='center'>Clic H bracelet</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_02} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>110,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CELINE</Typography>
                                    <Typography fontSize={18} align='center'>Shoulder bag Claude</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_03} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>265,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>Chanel 19 Handbag Beige</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_04} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>216,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>22 mini handbag</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_05} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>242,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>Flip bag with top Handle</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_06} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>20,100 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={18} align='center'>Mini Kelly Double Tour bracelet</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={11}>
                                <Typography ref={accessories_pc} fontSize={36} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={15}>Accessories</Typography>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_01} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>27,600 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={18} align='center'>Clic H bracelet</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_07} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>42,200 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>Earrings Very good condition Gold, Metal</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_08} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>22,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={18} align='center'>Tribales Earrings</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_09} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>31,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Stack>

                                        <Typography fontSize={18} align='center'>Tribales Earrings</Typography>
                                        <Typography fontSize={18} align='center'>Matte Gold-FinishMetalWhiteResin</Typography>
                                        <Typography fontSize={18} align='center'>Pearls and Silver-Tone</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_10} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>289,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Cartier</Typography>
                                    <Typography fontSize={18} align='center'>LOVE bracelet white gold</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_06} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>20,100 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={18} align='center'>Mini Kelly Double Tour bracelet</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={11}>
                                <Typography ref={bags_pc} fontSize={36} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={15}>Bags</Typography>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_11} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>110,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CELINE</Typography>
                                    <Typography fontSize={18} align='center'>SHOULDER BAG CLAUDE ICANVAS TAN</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_02} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>110,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CELINE</Typography>
                                    <Typography fontSize={18} align='center'>Shoulder bag Claude</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_03} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>265,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>Chanel 19 Handbag Beige</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_04} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>216,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>22 mini handbag</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_05} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>242,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>Flip bag with top Handle</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_12} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>404,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>SMALL CLASSIC HANDBAG</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_13} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>195,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={18} align='center'>Medium Lady D-Joy Bag Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_14} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>220,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={18} align='center'>Small Lady Dior My ABCDior</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_15} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>256,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Louis Vuitton</Typography>
                                    <Typography fontSize={18} align='center'>Capucines BB</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_16} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>232,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Louis Vuitton</Typography>
                                    <Typography fontSize={18} align='center'>Mini Capucines BB</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_17} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>81,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Louis Vuitton</Typography>
                                    <Typography fontSize={18} align='center'>Speedy Bandoulière 20</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_18} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>88,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Prada</Typography>
                                    <Typography fontSize={18} align='center'>Medium linen blend and leather tote bag</Typography>
                                </Stack>
                            </Grid>


                            <Grid item xs={11}>
                                <Typography ref={cloths_pc} fontSize={36} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={15}>Clothes</Typography>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_19} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>87,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={18} align='center'>Short-Sleeved Sweater</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_20} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>81,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={18} align='center'>Sweater Black Cashmere Knit</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_21} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>145,700 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={18} align='center'>Short Belted Dress Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_22} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>77,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={18} align='center'>Chez Moi Pants</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_23} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>130,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={18} align='center'>iviera Mid-Length Shirtdress</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_24} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>130,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={18} align='center'>Macrocannage Sleeveless</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={11}>
                                <Typography ref={footwears_pc} fontSize={36} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={15}>Footwears</Typography>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_25} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>59,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>SAINT Laurent</Typography>
                                    <Typography fontSize={18} align='center'>OPYUM PUMPS 110 mm</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_26} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>80,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>Black Turnlock Loafer</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_27} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>50,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={18} align='center'>MARY JANES Lambskin & Grosgrain Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_28} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>42,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={18} align='center'>J Adior Slingback Pump Black Calfsk</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_29} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>67,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Valentino</Typography>
                                    <Typography fontSize={18} align='center'>VLOGO SIGNATURE SLINGBACK PUMP IN CALFSKIN LEATHER 80MM</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_30} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>60,600 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={18} align='center'>Oran sandal Vert Celadon</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_31} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>29,200 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={18} align='center'>Oasis sandal Noir 5 cm</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_32} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>74,100 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={18} align='center'>Frenchie 50 ankle boot</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3.66}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_33} width='100%' height='auto' />
                                        <Typography fontSize={20} sx={{ position: 'absolute', bottom: 10, right: 15, color: '#2e2e2e' }}>50,020 THB</Typography>
                                    </Box>
                                    <Typography fontSize={20} align='center' fontFamily={'Playfair Display'} fontWeight={600}>SAINT Laurent</Typography>
                                    <Typography fontSize={18} align='center'>LEE SLINGBACK PUMPS IN PATENT LEATHER 7.5 cm</Typography>
                                </Stack>
                            </Grid>
                        </Grid>


                        <Grid container spacing={5} justifyContent='center' mt={5}>
                            <Grid item xs={5}>
                                <img src={image_1} width='100%' height='auto' />
                            </Grid>
                            <Grid item xs={5}>
                                <Stack direction='row' spacing={5} justifyContent='center'>
                                    <Avatar src={icon_shield || ''} variant='square' sx={{ width: '100px', height: '100px' }} />
                                    <Stack>
                                        <Typography ref={sell_with_us_pc} fontSize={36} fontFamily={'Playfair Display'} fontWeight={600}>Authenticity</Typography>
                                        <Typography fontSize={36} fontFamily={'Playfair Display'} fontWeight={600}>Guarantee</Typography>
                                    </Stack>
                                </Stack>
                                <Typography mt={3} align='justify'>Every piece in our collection is assuredly authentic, backed by a money-back guarantee. Should any item from our store be proven otherwise, we offer a full refund. Our legal registration provides you with the confidence that <b>we prioritize the authenticity and quality of every item we offer.</b></Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack height='100%' alignItems='center' justifyContent='center'>
                                    <Box sx={{ p: 2, pt: 0, border: 'solid 5px #000000' }}>
                                        <Stack alignItems='center'>
                                            <Typography fontSize={52} fontFamily={'Playfair Display'} fontWeight={500}>Sell with us</Typography>
                                            <Typography fontWeight={500}>When You Wish to Sell Your Items</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack direction='row' spacing={3} alignItems='center' mt={5}>
                                    <Typography fontSize={36} fontFamily={'Playfair Display'} fontWeight={600}>1</Typography>
                                    <Typography align='justify'>Connect with us on Line ID: luxurysiam or email us, including pictures, details, year, and accessories of your item.</Typography>
                                </Stack>
                                <Stack direction='row' spacing={3} alignItems='center' mt={5}>
                                    <Typography fontSize={36} fontFamily={'Playfair Display'} fontWeight={600}>2</Typography>
                                    <Typography align='justify'>Await our response and price evaluation until we reach a mutual agreement.</Typography>
                                </Stack>
                                <Stack direction='row' spacing={3} alignItems='center' mt={5}>
                                    <Typography fontSize={36} fontFamily={'Playfair Display'} fontWeight={600}>3</Typography>
                                    <Typography align='justify'>Once we decide to purchase, send the item to us for inspection, and we will transfer the payment immediately after verification.</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>

                    <Container maxWidth='lg' sx={{ backgroundColor: '#000000' }}>
                        <Grid container spacing={5} justifyContent='center' mt={5} pb={6} pt={2} >
                            <Grid item xs={5}>
                                <Stack height='100%' alignItems='center' justifyContent='center'>
                                    <Box sx={{ p: 2, pt: 0, border: 'solid 5px #000000' }}>
                                        <Stack >
                                            <Typography color='#fff' fontSize={52} fontFamily={'Playfair Display'} fontWeight={500}>Consignment</Typography>
                                            <Typography color='#fff' fontSize={52} fontFamily={'Playfair Display'} fontWeight={500}>Process</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack spacing={3}>
                                    <Stack direction='row' spacing={3} alignItems='center'>
                                        <Avatar sx={{ backgroundColor: '#fff', width: 15, height: 15 }} />
                                        <Typography align='justify' s color='#fff'>Notify us of your intention to consign an item. Send us photos, details, and your desired selling price.</Typography>
                                    </Stack>
                                    <Stack direction='row' spacing={3} alignItems='center'>
                                        <Avatar sx={{ backgroundColor: '#fff', width: 15, height: 15 }} />
                                        <Typography align='justify' color='#fff'>Upon agreeing on the consignment price, send the item to us. We will inspect, photograph, and list the item for sale. If any consigned item is found to be counterfeit, we will pursue legal action.</Typography>
                                    </Stack>
                                    <Stack direction='row' spacing={3} alignItems='center'>
                                        <Avatar sx={{ backgroundColor: '#fff', width: 15, height: 15 }} />
                                        <Typography align='justify' color='#fff'>We charge a 7% consignment fee based on the sale price. Rest assured, we meticulously care for all consigned items. Once sold, we will transfer the payment to you within 1-3 days.</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>

                    <Container maxWidth='lg' >
                        <Grid container spacing={5} justifyContent='center' mt={5} pb={6} pt={2} >
                            <Grid item xs={12}>
                                <Typography ref={customer_pc} fontSize={36} fontFamily={'Playfair Display'} fontWeight={500}>Customer Service</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack direction='row' spacing={5} alignItems='center' justifyContent='center'>
                                    <Avatar src={icon_s} sx={{ width: 60, height: 60 }} />
                                    <Stack spacing={2}>
                                        <Typography fontWeight='bold'>Payment Methods</Typography>
                                        <Stack >
                                            <Typography>We offer various</Typography>
                                            <Typography>convenient payment</Typography>
                                            <Typography>methods for your ease.</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack direction='row' spacing={5} alignItems='center' justifyContent='center'>
                                    <Avatar src={icon_ring} sx={{ width: 60, height: 60 }} />
                                    <Stack spacing={2}>
                                        <Typography fontWeight='bold'>Payment Notification</Typography>
                                        <Stack >
                                            <Typography>Please inform us once the</Typography>
                                            <Typography>payment is made to ensure</Typography>
                                            <Typography>a smooth transaction.</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item sx={10}>
                                <Stack direction='row' spacing={20} mt={5}>
                                    <Stack spacing={1}>
                                        <Typography>Email : sk@luxurysiam.com</Typography>
                                        <Typography>Hotline : 090-009-9900</Typography>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <Typography>Instagram : luxurysiam</Typography>
                                        <Typography>Line : @luxurysiam</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction='row' justifyContent='center' spacing={5} mt={8}>
                                    <Avatar src={icon_mail} sx={{ width: 40, height: 40 }} />
                                    <Avatar src={icon_ig} sx={{ width: 40, height: 40 }} onClick={() => window.open('https://www.instagram.com/luxurysiam/')} />

                                    <Avatar src={icon_line} sx={{ width: 40, height: 40 }} />
                                    <Avatar src={icon_phone} sx={{ width: 40, height: 40 }} onClick={() => window.open(`tel:+66900099900`)} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box sx={{ height: '70px', width: '100%' }} />
                </div>
            </Box>

            <Box sx={{ display: { lg: 'none', md: 'none', xs: 'flex' } }}>
                <div className='first' style={{
                    backgroundImage:`url(${videoHeader_MB})`,
                    backgroundSize:'cover',
                    position: 'fixed',
                    height: '100vh',
                    width: '100%'
                }}>
                    <Container maxWidth='lg'>
                        <Stack spacing={10} height='100vh' justifyContent='center' alignItems='center'>
                            <Typography align='center' color='white' fontSize={30} fontFamily={'Playfair Display'} fontWeight={700}>LUXURYSIAM</Typography>
                            <Stack spacing={2}>
                                <Stack direction='row' spacing={2} justifyContent='center'>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => about_us.current?.scrollIntoView({ behavior: 'smooth' })}>About us</Typography>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => trending.current?.scrollIntoView({ behavior: 'smooth' })}>Trending</Typography>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => accessories.current?.scrollIntoView({ behavior: 'smooth' })}>Accessories</Typography>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => bags.current?.scrollIntoView({ behavior: 'smooth' })}>Bags</Typography>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => cloths.current?.scrollIntoView({ behavior: 'smooth' })}>Clothes</Typography>
                                </Stack>
                                <Stack direction='row' spacing={2} justifyContent='center'>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => footwears.current?.scrollIntoView({ behavior: 'smooth' })}>Footwears</Typography>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => sell_with_us.current?.scrollIntoView({ behavior: 'smooth' })}>Sell with us</Typography>
                                    <Typography sx={{ p: 0, color: '#fff', fontSize: '11px', fontWeight: 'bold' }} onClick={() => customer.current?.scrollIntoView({ behavior: 'smooth' })}>Customer service</Typography>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Stack direction='row' spacing={3} alignItems='center' justifyContent='center'>
                                    <Stack>
                                        <Typography color='white' align='center' fontWeight={700} fontSize={22}>Trends come and go,</Typography>
                                        <Typography color='white' align='center' fontWeight={700} fontSize={22}>but classics are forever.</Typography>
                                    </Stack>
                                </Stack>
                                <Grid container justifyContent='center'>
                                    <Grid item xs={10}>
                                        <Typography color='white' align='justify' fontWeight={600} mt={10} fontSize={11}>With the Anatomy of an Investment Piece, we highlight the timeless styles you need in your wardrobe and break down what exactly makes them worth buying. Whether it’s a forever bag, wear-everywhere coat, or footwear staple, these fashion pieces are icons in the making.</Typography>
                                    </Grid>
                                </Grid>
                            </Stack>

                        </Stack>
                        <Stack alignItems='center' sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%' }}>
                            <IconButton onClick={() => about_us.current?.scrollIntoView({ behavior: 'smooth' })}>
                                <Avatar src={arrow} sx={{ width: 15, height: 15 }} />
                            </IconButton>
                        </Stack>
                    </Container>
                </div>

                <div style={{ position: 'relative', left: 0, width: '100%', top: 'calc(100vh + 5vh)', backgroundColor: '#fff' }}>
                    <Container maxWidth='xs'>
                        <Grid container spacing={2} justifyContent='center' alignItems='center' pt={5}>
                            <Grid item xs={12}>
                                <Typography ref={about_us} align='center' fontSize={18} fontFamily={'Playfair Display'} fontWeight={700}>Luxurysiam's</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography fontSize={7} textAlign='justify'>
                                    <b>story begins with a profound passion for luxury brands</b> and an appreciation for their rich histories. The timeless elegance of designer apparel, shoes, bags, and accessories not only enhances your personal style but also reflects sophistication and credibility. Luxury brands epitomize global fashion, remaining perpetually in vogue and becoming cherished heirlooms passed down through generations.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    backgroundImage: `url(${banner_01})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    height: '120px'
                                }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={2}>
                                    <Stack direction='row'>
                                        <Stack justifyContent='flex-start' minHeight='28vh'>
                                            <img src={bag_02_mobile} width='auto' height='190vh' />
                                        </Stack>
                                        <Stack alignItems='center'>
                                            <Typography align='center' fontSize={18} fontFamily={'Playfair Display'} fontWeight={700}>Established in 2012,</Typography>
                                            <Typography align='justify' fontSize={7} mt={2}>our journey started with personal collections that sparked the idea of offering pristine pre-owned items. Our selection boasts items in impeccable condition, ranging from 95-99%, complete with original accessories, and offered at a more attractive price than new. We also curate a collection of brand-new items for discerning customers.</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction='row' spacing={1}>
                                        <Typography fontSize={7} textAlign='justify'>
                                            Additionally, <b>we specialize in sourcing rare and coveted pieces tailored to your desires,</b> whether they hail from England, France, or Italy, thanks to our frequent international travels. <b>Luxurysiam Co., Ltd</b> was founded to facilitate the acquisition and sale of authentic luxury items. Our headquarters, legally registered and based in Bangkok, ensure trust and credibility.
                                        </Typography>
                                        <Stack justifyContent='flex-start' minHeight='25vh'>
                                            <img src={bag_01_mobile} width='auto' height='190vh' style={{ marginTop: '-10vh' }} />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={4} >
                            <Grid item xs={12}>
                                <Typography ref={trending} fontSize={18} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600}>Trending</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_01} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>27,600 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={10} align='center'>Clic H bracelet</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_02} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>110,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CELINE</Typography>
                                    <Typography fontSize={10} align='center'>Shoulder bag Claude</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_03} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>265,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>Chanel 19 Handbag Beige</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_04} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>216,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>22 mini handbag</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_05} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>242,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>Flip bag with top Handle</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_06} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>20,100 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={10} align='center'>Mini Kelly Double Tour bracelet</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography ref={accessories} fontSize={18} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={5}>Accessories</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_01} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>27,600 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={10} align='center'>Clic H bracelet</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_07} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>42,200 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>Earrings Very good condition Gold, Metal</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_08} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>22,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={10} align='center'>Tribales Earrings</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_09} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>31,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Stack>

                                        <Typography fontSize={10} align='center'>Tribales Earrings</Typography>
                                        <Typography fontSize={10} align='center'>Matte Gold-FinishMetalWhiteResin</Typography>
                                        <Typography fontSize={10} align='center'>Pearls and Silver-Tone</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_10} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>289,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Cartier</Typography>
                                    <Typography fontSize={10} align='center'>LOVE bracelet white gold</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_06} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>20,100 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={10} align='center'>Mini Kelly Double Tour bracelet</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography ref={bags} fontSize={18} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={5}>Bags</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_11} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>110,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CELINE</Typography>
                                    <Typography fontSize={10} align='center'>SHOULDER BAG CLAUDE ICANVAS TAN</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_02} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>110,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CELINE</Typography>
                                    <Typography fontSize={10} align='center'>Shoulder bag Claude</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_03} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>265,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>Chanel 19 Handbag Beige</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_04} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>216,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>22 mini handbag</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_05} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>242,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>Flip bag with top Handle</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_12} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>404,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>SMALL CLASSIC HANDBAG</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_13} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>195,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={10} align='center'>Medium Lady D-Joy Bag Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_14} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>220,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={10} align='center'>Small Lady Dior My ABCDior</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_15} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>256,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Louis Vuitton</Typography>
                                    <Typography fontSize={10} align='center'>Capucines BB</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_16} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>232,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Louis Vuitton</Typography>
                                    <Typography fontSize={10} align='center'>Mini Capucines BB</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_17} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>81,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Louis Vuitton</Typography>
                                    <Typography fontSize={10} align='center'>Speedy Bandoulière 20</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_18} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>88,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Prada</Typography>
                                    <Typography fontSize={10} align='center'>Medium linen blend and leather tote bag</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography ref={cloths} fontSize={18} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={5}>Clothes</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_19} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>87,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={10} align='center'>Short-Sleeved Sweater</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_20} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>81,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={10} align='center'>Sweater Black Cashmere Knit</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_21} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>145,700 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={10} align='center'>Short Belted Dress Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_22} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>77,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={10} align='center'>Chez Moi Pants</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_23} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>130,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={10} align='center'>iviera Mid-Length Shirtdress</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_24} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>130,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Dior</Typography>
                                    <Typography fontSize={10} align='center'>Macrocannage Sleeveless</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography ref={footwears} fontSize={18} fontFamily={'Playfair Display'} letterSpacing={4} fontWeight={600} mt={5}>Footwears</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_25} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>59,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>SAINT Laurent</Typography>
                                    <Typography fontSize={10} align='center'>OPYUM PUMPS 110 mm</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_26} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>80,500 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>Black Turnlock Loafer</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_27} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>50,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>CHANEL</Typography>
                                    <Typography fontSize={10} align='center'>MARY JANES Lambskin & Grosgrain Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_28} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>42,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>DIOR</Typography>
                                    <Typography fontSize={10} align='center'>J Adior Slingback Pump Black Calfsk</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_29} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>67,000 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Valentino</Typography>
                                    <Typography fontSize={10} align='center'>VLOGO SIGNATURE SLINGBACK PUMP IN CALFSKIN LEATHER 80MM</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_30} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>60,600 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={10} align='center'>Oran sandal Vert Celadon</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_31} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>29,200 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={10} align='center'>Oasis sandal Noir 5 cm</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_32} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>74,100 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>Hermes</Typography>
                                    <Typography fontSize={10} align='center'>Frenchie 50 ankle boot</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <img src={product_33} width='100%' height='auto' />
                                        <Typography fontSize={10} sx={{ position: 'absolute', bottom: 5, right: 5, color: '#2e2e2e' }}>50,020 THB</Typography>
                                    </Box>
                                    <Typography fontSize={10} align='center' fontFamily={'Playfair Display'} fontWeight={600}>SAINT Laurent</Typography>
                                    <Typography fontSize={10} align='center'>LEE SLINGBACK PUMPS IN PATENT LEATHER 7.5 cm</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent='center' mt={5}>
                            <Grid item xs={6}>
                                <Stack height='100%' alignItems='center' justifyContent='center' sx={{
                                    backgroundImage: `url(${image_1_mobile})`,
                                    backgroundSize: 'cover',
                                }}>
                                    {/* <img src={image_1_mobile} height='100%' width='auto' /> */}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                                    <Avatar src={icon_shield || ''} variant='square' sx={{ width: '50px', height: '50px' }} />
                                    <Stack>
                                        <Typography ref={sell_with_us} fontSize={18} fontFamily={'Playfair Display'} fontWeight={600}>Authenticity</Typography>
                                        <Typography fontSize={18} fontFamily={'Playfair Display'} fontWeight={600}>Guarantee</Typography>
                                    </Stack>
                                </Stack>
                                <Typography mt={2} align='justify' fontSize={7}>Every piece in our collection is assuredly authentic, backed by a money-back guarantee. Should any item from our store be proven otherwise, we offer a full refund. Our legal registration provides you with the confidence that <b>we prioritize the authenticity and quality of every item we offer.</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack height='100%' alignItems='center' justifyContent='center'>
                                    <Box sx={{ p: 1, pt: 0, border: 'solid 3px #000000' }}>
                                        <Stack alignItems='center'>
                                            <Typography fontSize={22} fontFamily={'Playfair Display'} fontWeight={500}>Sell with us</Typography>
                                            <Typography fontSize={7} fontWeight={500}>When You Wish to Sell Your Items</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={2} mt={2}>
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Typography fontSize={18} fontFamily={'Playfair Display'} fontWeight={600}>1</Typography>
                                        <Typography align='justify' fontSize={7}>Connect with us on Line ID: luxurysiam or email us, including pictures, details, year, and accessories of your item.</Typography>
                                    </Stack>
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Typography fontSize={18} fontFamily={'Playfair Display'} fontWeight={600}>2</Typography>
                                        <Typography align='justify' fontSize={7}>Await our response and price evaluation until we reach a mutual agreement.</Typography>
                                    </Stack>
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Typography fontSize={18} fontFamily={'Playfair Display'} fontWeight={600}>3</Typography>
                                        <Typography align='justify' fontSize={7}>Once we decide to purchase, send the item to us for inspection, and we will transfer the payment immediately after verification.</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>

                    <Container maxWidth='lg' sx={{ backgroundColor: '#000000' }}>
                        <Grid container spacing={2} justifyContent='center' mt={5} pt={2} pb={4}>
                            <Grid item xs={5}>
                                <Stack height='100%' alignItems='center' justifyContent='center'>
                                    <Box sx={{ p: 2, pt: 0, border: 'solid 5px #000000' }}>
                                        <Stack >
                                            <Typography color='#fff' fontSize={18} fontFamily={'Playfair Display'} fontWeight={500}>Consignment</Typography>
                                            <Typography color='#fff' fontSize={18} fontFamily={'Playfair Display'} fontWeight={500}>Process</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={7}>
                                <Stepper activeStep={true} orientation="vertical" connector={<QontoConnector />}>
                                    <Step key={'Order Placed'}>
                                        <StepLabel StepIconComponent={QontoStepIcon}>
                                            <Typography fontSize={7} align='justify' color='#fff'>Notify us of your intention to consign an item. Send us photos, details, and your desired selling price.</Typography>
                                        </StepLabel>
                                    </Step>
                                    <Step key={'Order Confirmed'}>
                                        <StepLabel StepIconComponent={QontoStepIcon}>
                                            <Typography fontSize={7} align='justify' color='#fff'>Upon agreeing on the consignment price, send the item to us. We will inspect, photograph, and list the item for sale. If any consigned item is found to be counterfeit, we will pursue legal action.</Typography>
                                        </StepLabel>
                                    </Step>
                                    <Step key={'Order Processed'}>
                                        <StepLabel StepIconComponent={QontoStepIcon}>
                                            <Typography fontSize={7} align='justify' color='#fff'>We charge a 7% consignment fee based on the sale price. Rest assured, we meticulously care for all consigned items. Once sold, we will transfer the payment to you within 1-3 days.</Typography>
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                            </Grid>
                        </Grid>
                    </Container>

                    <Container maxWidth='lg' >
                        <Grid container spacing={3} justifyContent='center' pb={6} pt={5} >
                            <Grid item xs={12}>
                                <Typography ref={customer} fontSize={18} fontFamily={'Playfair Display'} fontWeight={500}>Customer Service</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack alignItems='center' spacing={3}>
                                    <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                                        <Avatar src={icon_s} sx={{ width: 35, height: 35 }} />
                                        <Stack spacing={2}>
                                            <Typography fontWeight='bold' fontSize={7}>Payment Methods</Typography>
                                            <Stack >
                                                <Typography fontSize={7}>We offer various</Typography>
                                                <Typography fontSize={7}>convenient payment</Typography>
                                                <Typography fontSize={7}>methods for your ease.</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <Typography fontSize={7}>Email : sk@luxurysiam.com</Typography>
                                        <Typography fontSize={7}>Hotline : 090-009-9900</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack alignItems='center' spacing={3}>
                                    <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                                        <Avatar src={icon_ring} sx={{ width: 35, height: 35 }} />
                                        <Stack spacing={2}>
                                            <Typography fontWeight='bold' fontSize={7}>Payment Notification</Typography>
                                            <Typography fontSize={7}>Please inform us once the payment is made to ensure a smooth transaction.</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <Typography fontSize={7}>Instagram : luxurysiam</Typography>
                                        <Typography fontSize={7}>Line : @luxurysiam</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item sx={10}>
                                <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
                                    <Avatar src={icon_mail} sx={{ width: 30, height: 30 }} />
                                    <Avatar src={icon_ig} sx={{ width: 30, height: 30 }} onClick={() => window.open('https://www.instagram.com/luxurysiam/')} />
                                    <Avatar src={icon_line} sx={{ width: 30, height: 30 }} />
                                    <Avatar src={icon_phone} sx={{ width: 30, height: 30 }} onClick={() => window.open(`tel:+66900099900`)} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Box>
        </>
    )
}

export default HomeTH

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)"
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderLeft: 'solid 2px #3f51b5',
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderLeft: 'solid 2px #3f51b5',
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderLeft: 'solid 1.3px ',
        height: 5,
        borderColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderTopWidth: 10,

    }
}));


const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ffffff",
    display: "flex",
    height: 0,
    alignItems: "center",
    marginLeft: 9,
    marginRight: 10,
    ...(ownerState.active && {
        color: "#ffffff"
    }),
    "& .QontoStepIcon-completedIcon": {
        width: 7,
        height: 7,
        borderRadius: "50%",
        backgroundColor: "#ffffff"
    },
    "& .QontoStepIcon-circle": {
        width: 7,
        height: 7,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    }
}));
function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <div className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}