import React, { createContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
const ThemeContext = createContext({});

const ThemeProviderContext = ({ children }) => {

  const Themes = createTheme({
    shape: {
      borderRadius: 10
    },
    palette: {
      background: {
        default: '#ffffff',
        paper: colors.common.white
      },
      primary: {
        main: "#70B9B0",
        light: "#7AC1BF",
        dark: "#65A5A0",
        contrastText: '#ffffff'
      },
      secondary: {
        main: "#FDC309",
        light: "#FDB309",
        dark: "#FDB309",
        contrastText: '#ffffff'
      },
      neutral: {
        main: "#0a0a0a"
      },
      success: {
        main: '#43a047',
        contrastText: '#fff'
      },
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      color: '#000000',
      fontSize: 14,
      fontWeightMedium: 500,
      fontWeightRegular: 400,
      fontWeightBold: 600,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Poppins');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
      MuiChip: {
        styleOverrides: {
          icon: {
            color: '#1e1e1e'
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            // border:'none'
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform:'inherit',
            boxShadow: 'none',
            borderRadius: 20,
            fontSize: 16,
            fontWeight: 600,
            justifyContent: 'center',
            padding: '5px 20px 5px 20px'
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderRadius: 50,
            // padding: '0px'
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            maxHeight: '40vh'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: '#fff',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#f2f2f2'
            },
            '&:hover $notchedOutline': {
              borderColor: '#E3C35700'
            },
            '&.MuiInputBase-multiline': {
              padding: 1
            },
            "& .MuiInputBase-input": {
              padding: "12px 12px 10px 15px",
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
              },
            },
            "& fieldset": {
              borderRadius: `10px`
            },
          },
          input: {
            borderRadius: 10,
            color: '#1e1e1e',
            '&::placeholder': {
              color: '#1e1e1e',
              fontSize: '0.975rem'
            }
          }
        }
      },
    },
  });

  return (
    <ThemeContext.Provider value={Themes}>
      <ThemeProvider theme={Themes}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProviderContext };
